<template>
  <VContainer fluid>
    <VForm
      ref="form"
      v-model="valid"
      @submit.prevent="reset"
    >
      <header>
        <img
          class="logo"
          :src="(vendor.branding && vendor.branding.logo) ? `/${vendor.branding.logo}` : logotype"
          :alt="vendor.title"
          aria-hidden="true"
        >
        <h1 class="page-title">
          {{ localDocumentTitle }}
        </h1>
      </header>
      <VTextField
        v-model="username"
        outlined
        persistent-hint
        :label="$t('anonymous_your_login_or_email.one')"
        autofocus
        name="username"
        :rules="[v => !!v || 'Это поле обязательно']"
        maxlength="254"
        autocomplete="username email"
        required
      />
      <VBtn
        x-large
        type="submit"
        block
        color="primary"
        :loading="isSubmitting"
      >
        {{ $t('anonymous_reset_password.one') }}
      </VBtn>

      <footer class="text-center">
        <VCardText>
          <p v-if="vendor.branding.self_registration_enabled">
            {{ $t('anonymous_first_question.one') }} <RouterLink to="/register">
              {{ $t('anonymous_register.one') }}
            </RouterLink>
          </p>
          Уже есть аккаунт? <RouterLink to="/login">
            {{ $t('anonymous_sign_in.one') }}
          </RouterLink>
        </VCardText>
        <VDivider />
        <VCardText>
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >{{ $t('personal_data_processing_policy.one') }}</a>
        </VCardText>
      </footer>
    </VForm>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import documentTitle from '../mixins/documentTitle'
import logotype from '../assets/images/uchi.svg'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheAccountReset',
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'anonymous_forgot_password.one',
      valid: false,
      username: '',
      logotype,
      isSubmitting: false
    }
  },

  computed: {
    ...mapGetters({
      cachedUserName: getters.USERNAME,
      vendor: getters.VENDOR
    })
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      resetPassword: actions.RESET_PASSWORD
    }),

    async reset () {
      const form = this.$refs.form
      if (!form.validate()) { return }

      this.isSubmitting = true

      await this.resetPassword(new FormData(form.$el))
      this.isSubmitting = false
    },

    register () {
      this.username = this.cachedUserName
    }
  }
}
</script>
